import { useDispatch, useSelector } from "react-redux";
import TableList from "../components/table-list";
import { courseTypesReducer } from "../redux/reducers";
import Dialog from "../components/dialog";
import { useState } from "react";
import { updateCourseTypes } from "../redux/lists-slice";

const _headers = [
  { key: "name", title: "Name" },
  { key: "code", title: "Code" },
  { key: "description", title: "Description" },
];

const CourseType = () => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const lists = useSelector(courseTypesReducer);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCourseTypes({
        name: name,
        code: code,
        description: description,
      })
    );
    setDialog(false);
  };
  return (
    <>
      <TableList
        headers={_headers}
        loading={false}
        data={lists}
        error={null}
        onClick={() => setDialog(true)}
      />
      {dialog && (
        <Dialog open={dialog} setOpen={() => setDialog(false)}>
          <form onSubmit={handleSubmit} className="flex flex-col gap3">
            <div className="flex gap3">
              <input
                type="text"
                required
                placeholder="Title"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                required
                placeholder="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <textarea
              placeholder="Description"
              required
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="flex gap3">
              <button type="submit">Submit</button>
              <button type="reset" onClick={() => setDialog(false)}>
                Cancel
              </button>
            </div>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default CourseType;
