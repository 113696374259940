import Header from "./components/app/header";
import Drawer from "./components/app/darwer";
import Footer from "./components/app/footer";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "./utils/route-contants";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticatedReducer } from "./redux/reducers";

const App = () => {
  const isAuthenticated = useSelector(isAuthenticatedReducer);
  const location = useLocation(); // Get the current route

  if (!isAuthenticated && location.pathname !== LOGIN_PATH) {
    return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
  }

  return (
    <div className="app">
      <Drawer />
      <section>
        <Header />
        <div className="body flex">
          <div className="innder__body">
            <Outlet />
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};
export default App;
