// import TableList from "../components/table-list";
// import { useGetPostsQuery } from "../services/lists-service";

// const _headers = [
//   { key: "title", title: "Title" },
//   { key: "body", title: "Post" },
// ];

// const Students = () => {
//   const { isLoading, data, error } = useGetPostsQuery();
//   return (
//     <TableList
//       headers={_headers}
//       loading={isLoading}
//       data={data}
//       error={error}
//     />
//   );
// };

// export default Students;

import TableList from "../../components/table-list";
const _headers = [
  { key: "name", title: "Name" },
  { key: "country", title: "Country" },
  { key: "type", title: "Type" },
  { key: "category", title: "Category" },
  { key: "phone", title: "Phone" },
  { key: "email", title: "Email" },
  { key: "website", title: "Website" },
  { key: "street", title: "Street" },
  { key: "muncipality", title: "Muncipality" },
  { key: "district", title: "District" },
  { key: "province", title: "Province" },
  { key: "year", title: "Established Year" },
  { key: "principal_name", title: "Principal Name" },
  { key: "principal_mobile", title: "Principal Mobile" },
  { key: "principal_email", title: "Principal Email" },
];
const Students = () => {
  return (
    <TableList
      headers={_headers}
      loading={false}
      data={[]}
      error={null}
      isAdd={true}
    />
  );
};

export default Students;
