const AddOffer = () => {
  return (
    <div className="flex gap4">
      <input type="text" placeholder="Code" />
      <div>
        <input type="checkbox" />
        <label>Percentage</label>
      </div>
      <input type="text" placeholder="Amount" />
      <input type="text" placeholder="Start Date" />
      <input type="text" placeholder="End Date" />
      <input type="text" placeholder="Times..." />
      <input type="text" placeholder="For Course..." />
    </div>
  );
};

export default AddOffer;
