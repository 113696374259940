import { useState } from "react";

/* basic info: first name, middle name, last name, email, phone, gender, dob, about, photo */
const BasicInfo = () => {
  const [info, setInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    about: "",
    photo: "",
  });

  return (
    <div className="flex flex-col gap2">
      <p>Basic Info</p>
      <div className="flex gap4">
        <input
          type="text"
          placeholder="First Name"
          value={info.firstName}
          onChange={(e) => setInfo({ ...info, firstName: e.target.value })}
        />
        <input
          type="text"
          placeholder="Middle Name"
          value={info.middleName}
          onChange={(e) => setInfo({ ...info, middleName: e.target.value })}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={info.lastName}
          onChange={(e) => setInfo({ ...info, lastName: e.target.value })}
        />
        <input
          type="text"
          placeholder="Email"
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="Phone"
          value={info.phone}
          onChange={(e) => setInfo({ ...info, phone: e.target.value })}
        />
        <select
          value={info.gender}
          onChange={(e) => setInfo({ ...info, gender: e.target.value })}
        >
          <option>Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <input
          type="date"
          placeholder="DOB"
          value={info.dob}
          onChange={(e) => setInfo({ ...info, dob: e.target.value })}
        />
      </div>
    </div>
  );
};

export default BasicInfo;
