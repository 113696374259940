import TableList from "../../components/table-list";
import { useGetUsersQuery } from "../../services/lists-service";

const _headers = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "email",
    title: "Email",
  },
  {
    key: "subject", // Assuming teachers have a subject
    title: "Subject",
  },
  {
    key: "action", // Assuming teachers have a subject
    title: "Action(s)",
  },
];

const Teachers = () => {
  // const { isLoading, data, error } = useGetUsersQuery();
  return (
    <TableList
      headers={_headers}
      loading={false}
      error={null}
      data={[]}
      isAdd={true}
    />
  );
};

export default Teachers;
