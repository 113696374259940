import { useDispatch } from "react-redux";
import { updateCourses } from "../../redux/lists-slice";
import { useState } from "react";

const AddCourse = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  // const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [fee, setFee] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCourses({
        name: name,
        duration: duration,
        fee: fee,
        type: type,
        start_date: start_date,
        end_date: end_date,
        description: description,
      })
    );
  };

  return (
    <form className="flex flex-col gap4" onSubmit={handleSubmit}>
      <div>
        <select value={type} onChange={(e) => setType(e.target.value)}>
          <option value="" disabled>
            Select Category
          </option>
          <option value="free">Entrance Preparations</option>
          <option value="paid">Paid</option>
        </select>
      </div>
      <input
        type="text"
        placeholder="Name*"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="flex gap3">
        <input
          type="text"
          placeholder="Duration"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
        <input
          type="text"
          placeholder="Base Fee"
          value={fee}
          onChange={(e) => setFee(e.target.value)}
        />
      </div>
      <div className="flex gap3">
        <input
          type="date"
          placeholder="Start Date"
          value={start_date}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          placeholder="End Date"
          value={end_date}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <label htmlFor="banner" className="banner__wrapper">
        <input type="file" />
      </label>
      <textarea
        placeholder="Description..."
        rows={10}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default AddCourse;
