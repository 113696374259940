import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  ROOT_PATH,
  STUDENTS_PATH,
  TEACHERS_PATH,
  SCHOOLS_PATH,
  LOGIN_PATH,
  COURSES_PATH,
  OFFERS_PATH,
  ADD_PATH,
  COURSE_TYPE_PATH,
} from "./utils/route-contants";
import Login from "./pages/login";
import App from "./App";
import Dashboard from "./pages/dashboard";
import Students from "./pages/students/students";
import AddStudents from "./pages/students/add_student";
import Teachers from "./pages/teachers/teachers";
import AddTeachers from "./pages/teachers/add_teacher";
import Schools from "./pages/schools/schools";
import AddSchool from "./pages/schools/add_school";
import PageNotFound from "./pages/page-not-found";
import Courses from "./pages/courses/courses";
import AddCourses from "./pages/courses/add_course";
import Offers from "./pages/offers/offers";
import AddOffer from "./pages/offers/add_offer";
import CourseType from "./pages/course_type";

const router = createBrowserRouter([
  { path: LOGIN_PATH, element: <Login /> },
  {
    path: ROOT_PATH,
    element: <App />,
    children: [
      { path: "", index: true, element: <Dashboard /> },
      {
        path: STUDENTS_PATH,
        children: [
          { path: "", index: true, element: <Students /> },
          { path: ADD_PATH, element: <AddStudents /> },
        ],
      },
      {
        path: TEACHERS_PATH,
        children: [
          { path: "", index: true, element: <Teachers /> },
          { path: ADD_PATH, element: <AddTeachers /> },
        ],
      },
      {
        path: SCHOOLS_PATH,
        children: [
          { path: "", index: true, element: <Schools /> },
          { path: ADD_PATH, element: <AddSchool /> },
        ],
      },
      {
        path: COURSES_PATH,
        children: [
          { path: "", index: true, element: <Courses /> },
          { path: ADD_PATH, element: <AddCourses /> },
        ],
      },
      {
        path: OFFERS_PATH,
        children: [
          { path: "", index: true, element: <Offers /> },
          { path: ADD_PATH, element: <AddOffer /> },
        ],
      },
      { path: COURSE_TYPE_PATH, element: <CourseType /> },
    ],
  },
  { path: "*", element: <PageNotFound /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
AOS.init();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
