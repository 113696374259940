import {
  ChevronUpSquare,
  CornerUpLeftIcon,
  LayoutDashboardIcon,
  UniversityIcon,
  Users,
  UsersRound,
} from "lucide-react";
import {
  COURSE_TYPE_PATH,
  COURSES_PATH,
  OFFERS_PATH,
  SCHOOLS_PATH,
  STUDENTS_PATH,
  TEACHERS_PATH,
} from "./route-contants";

export const sideMenuItems = [
  { icon: <LayoutDashboardIcon />, title: "Dashboard", route: "" },
  { icon: <Users />, title: "Students", route: STUDENTS_PATH },
  { icon: <UsersRound />, title: "Teachers", route: TEACHERS_PATH },
  { icon: <UniversityIcon />, title: "Schools", route: SCHOOLS_PATH },
  { icon: <CornerUpLeftIcon />, title: "Course Type", route: COURSE_TYPE_PATH },
  { icon: <ChevronUpSquare />, title: "Courses", route: COURSES_PATH },
  { icon: <CornerUpLeftIcon />, title: "Offers", route: OFFERS_PATH },
];
