import { PlusIcon, Trash2 } from "lucide-react";
import { useState } from "react";

const EducationInfo = () => {
  const [educations, setEducations] = useState([{ level: "", school: "" }]);
  return (
    <div className="flex flex-col gap2">
      <div className="flex gap4">
        <p>Education</p>
        <button
          className="small square"
          onClick={() => {
            var res = [...educations];
            res.push({});
            setEducations(res);
          }}
        >
          <PlusIcon size={20} />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Education Level</th>
            <th>School/College/University</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {educations.map((item, i) => (
            <tr key={i}>
              <td>
                <select
                  placeholder="Education Level"
                  value={item.level}
                  onChange={(e) => {
                    var res = [...educations];
                    var obj = {
                      ...item,
                      level: e.target.value,
                    };
                    res[i] = obj;
                    setEducations(res);
                  }}
                >
                  <option value="" disabled>
                    Select Education
                  </option>
                  <option value="SLC">SLC</option>
                  <option value="Intermediate">Intermediate</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  placeholder="School/College/University"
                  value={item.school}
                  onChange={(e) => {
                    var res = [...educations];
                    var obj = {
                      ...item,
                      school: e.target.value,
                    };
                    res[i] = obj;
                    setEducations(res);
                  }}
                />
              </td>
              <td>
                {educations.length > 1 && (
                  <button
                    className="square small danger"
                    onClick={() => {
                      var res = [...educations];
                      res.splice(i, 1);
                      setEducations(res);
                    }}
                  >
                    <Trash2 />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EducationInfo;
