import { NavLink } from "react-router-dom";
import { sideMenuItems } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth-slice";
import { LogOut } from "lucide-react";

const Drawer = () => {
  const dispatch = useDispatch();
  return (
    <div className="side__menu">
      <div className="side__menu__list">
        {sideMenuItems.map((menu, i) => (
          <NavLink key={i} to={menu.route}>
            {menu.icon}
            <span>{menu.title}</span>
          </NavLink>
        ))}
      </div>
      <button onClick={() => dispatch(logout())}>
        <LogOut size={16} />
        <span>Logout</span>
      </button>
    </div>
  );
};
export default Drawer;
