import "../css/_dialog.scss";

const Dialog = ({ open, setOpen, children }) => {
  return (
    open && (
      <div
        className="dialog"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
      >
        <div
          className="dialog__container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default Dialog;
