import { useSelector } from "react-redux";
import TableList from "../../components/table-list";
import { coursesReducer } from "../../redux/reducers";

const _headers = [
  { key: "name", title: "Course Name" },
  { key: "country", title: "Duration" },
  { key: "type", title: "Base Fee" },
  { key: "start_date", title: "Start Date" },
  { key: "end_date", title: "End Date" },
  { key: "category", title: "Description" },
];

const Courses = () => {
  const lists = useSelector(coursesReducer);
  return (
    <TableList
      headers={_headers}
      loading={false}
      data={lists}
      error={null}
      isAdd={true}
    />
  );
};

export default Courses;
