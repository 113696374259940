import BasicInfo from "../../components/info/basic_info";
import GaurdianInfo from "../../components/info/gaurdian_info";
import AddressInfo from "../../components/info/address_info";
import EducationInfo from "../../components/info/education_info";

const AddStudents = () => {
  return (
    <div className="flex flex-col gap4">
      <BasicInfo />
      <GaurdianInfo />
      <AddressInfo />
      <AddressInfo title="Temporary Address" />
      <EducationInfo />
    </div>
  );
};

export default AddStudents;
